<template>
  <v-card>
    <TenderCard />
    <v-simple-table class="mx-5 my-3">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="error--text justify-center">
            <v-icon class="mr-2 mt-n1" color="grey">mdi-shopping</v-icon>
            <a small>Tender Details</a>
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Name</th>
            <th class="text-left">Detail</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Tender Title</td>
            <td>{{ tender.title }}</td>
          </tr>
          <tr></tr>
          <tr>
            <td>Tender Number</td>
            <td>{{ tender.tender_no }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td v-if="tender.status.slug == 'open'" class="success--text">
              {{ tender.status.name }}
            </td>
            <td v-if="tender.status.slug == 'closed'" class="error--text">
              {{ tender.status.name }}
            </td>
          </tr>
          <tr>
            <td>Application Fee</td>
            <td>{{ toMoneyFormat(tender.application_fee) }}</td>
          </tr>
          <tr>
            <td>Budget</td>
            <td>{{ toMoneyFormat(tender.budget) }}</td>
          </tr>
          <tr>
            <td>Closing Date</td>
            <td>{{ formatDate(tender.closing_date) }}</td>
          </tr>
          <tr>
            <td>Clarification End Date</td>
            <td>{{ formatDate(tender.clarification_end_date) }}</td>
          </tr>
          <tr>
            <td>Evaluation End Date</td>
            <td>{{ formatDate(tender.evaluation_end_date) }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>{{ tender.status.name }}</td>
          </tr>
          <tr>
            <td>Approval Date</td>
            <td>{{ formatDate(tender.tender_approval_date) }}</td>
          </tr>
          <tr>
            <td>Application Parts</td>
            <td>{{ tender.application_steps }}</td>
          </tr>
          <tr>
            <td>Documents</td>
            <td>
              <div class="py-1 my-3">
                <a
                  v-for="(item, i) in tender.documents"
                  :key="i"
                  link
                  v-bind:href="docURI"
                  class="text-decoration-underline py-5"
                  >({{ i + 1 }}) {{ item.name }}
                  <br />
                </a>
              </div>
            </td>
          </tr>
          <!-- <tr>
            <td>Advertised</td>
            <td>
                <div class="py-1 my-3">
                    <a v-for="(item, i) in tender.documents" :key="i" link v-bind:href="docURI" class="text-decoration-underline py-5">({{ i + 1 }}) {{ item.name }}
                      <br />
                    </a>
                </div>
            </td>
        </tr> -->
          <tr v-if="companyId != 1">
            <td>Action</td>
            <td>
              <v-dialog v-model="dialog" width="800">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="showInterestBtn"
                    color="error"
                    class="text-capitalize"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    text
                    outlined
                  >
                    Express Interest?
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    <p color="error">Interest for Participation</p>
                  </v-card-title>
                  <v-card-text  style="height: 680px;">
                    <v-textarea
                      outlined
                      name="input-7-4"
                      label="Message"
                      placeholder="Your message here"
                      class="mt-5"
                      color="grey"
                      :value="expressInterestText"
                      height="300"
                    ></v-textarea>
                  </v-card-text>
                  <v-divider class="mt-n7"></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" text @click="showInterest">
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-btn
                color="error "
                class="text-capitalize mx-3"
                rounded
                outlined
                v-if="applyBtn"
                @click="goToTender(tender.application_steps)"
                >Apply For Tender</v-btn
              >
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import TenderCard from "@/components/TenderCard";
import moment from "moment";

export default {
  components: {
    TenderCard,
  },
  data() {
    return {
      applyBtn: false,
      showInterestBtn: true,
      dialog: false,
      tender_attribute: [],
      tender: JSON.parse(localStorage.getItem("tenderItem")),
      docURI:
        "https://portal.ufaa.go.ke/frontend/web/index.php/page/download-all?tenderID=37",
      expressInterestText:
        "We Mumias Sugar Co, have downloaded the tender and intend on participating. Our details are as below. Kindly send us any clarifications related to this tender.",
    };
  },
  created() {
    this.setDetails(Object.entries(this.tender));
  },
  computed: {
    companyId() {
      return this.$store.state.auth.user.company_id;
    },
  },
  methods: {
    showInterest() {
      this.dialog = false;
      this.applyBtn = true;
      this.showInterestBtn = false;
    },
    setDetails(tender) {
      tender.forEach((element) => {
        this.tender_attribute.push(element);
      });
    },
    
    toMoneyFormat(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(input) {
      return moment(input).format("Do MMMM  YYYY, h:mm:ss a");
    },
    goToTender(application_steps) {
      this.$router.push("/tender-application-" + application_steps);
    },
    goToClarification() {
      this.$router.push("/clarification");
    },
  },
};
</script>